import { Controller } from "@hotwired/stimulus";

export default class CardsPanelController extends Controller {
  static values = {
    fetched: Boolean,
    url: String,
  };

  initialize() {
    this.index = parseInt(this.element.dataset.index);

    if (isDevEnvironment()) {
      console.info(`[DEV] Cards Panel Controller Initialized`);
    }
  }

  connect() {
    if (this.index == 0) {
      this.fetchCards();
    }
  }

  handlePanelOpen(event) {
    if (event.detail.index == this.index) {
      if (this.fetchedValue == false) {
        this.fetchCards();
      }
    }
  }

  fetchCards() {
    console.log(this.urlValue)
    fetch(this.urlValue, {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "text/html",
        "Accept": "text/html",
      }
    })
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error(`Response not ok: ${response.status}`);
        }
      }).then(data => {
        this.element.innerHTML = data;
        this.fetchedValue = true;
      })
  }
}
