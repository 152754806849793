import $ from 'jquery';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

function runTippy(element) {
  const content = element.dataset.tooltip;
  tippy(element, {
    duration: 0,
    arrow: false,
    delay: [0, 0],
    content: content,
  });
}

$(function(){
  var allTooltips = document.querySelectorAll('[data-tooltip]');
  for(var i=0; i < allTooltips.length;  i++) {
    runTippy(allTooltips[i])
  }
})
