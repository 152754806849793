import { Controller } from "@hotwired/stimulus";
import anime from 'animejs/lib/anime.es.js';

export default class CardCompleteDialogController extends Controller {
  initialize() {
    if (isDevEnvironment()) {
      console.info(`[DEV] Card Complete Dialog Controller Initialized`);
    }
  }

  handleAllActivitiesCompleted() {
    anime({
      targets: this.element,
      opacity: [0, 1],
      duration: 300,
      easing: 'easeInOutQuad',
      begin: () => {
        this.element.showModal();
      }
    });

    document.documentElement.classList.add("has-card-complete-dialog-open");
  }

  close() {
    anime({
      targets: this.element,
      opacity: [1, 0],
      duration: 300,
      easing: 'easeInOutQuad',
      complete: () => {
        this.element.close();
      }
    });
    document.documentElement.classList.remove("has-card-complete-dialog-open");
  }
}
