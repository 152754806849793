import { Controller } from "@hotwired/stimulus"

export default class CardCustomFormController extends Controller {
  static targets = ["submit", "title", "activity", "size", "count"];

  initialize() {
    this.size = this.getSize();
    this.selectedActivities = this.getNumberOfSelectedActivities();
    this.updateCount();
    this.submitTarget.disabled = true;

    if (isDevEnvironment()) {
      console.info(`[DEV] Card Custom Form Controller Initialized`);
    }
  }

  getSize() {
    const element = this.sizeTargets.find((size) => {
      return size.checked;
    });

    return parseInt(element.value);
  }

  getNumberOfSelectedActivities() {
    return this.activityTargets.filter((activity) => {
      return activity.checked;
    }).length;
  }

  handleTitleInput(_event) {
    this.validateForm();
  }

  handleActivityChange(_event) {
    this.selectedActivities = this.getNumberOfSelectedActivities();
    this.updateCount();
    this.validateForm();
  }

  handleSizeChange(event) {
    event.target.checked = true;
    this.size = this.getSize();
    this.updateCount();
    this.validateForm();
  }

  validateForm() {
    if (this.selectedActivities >= this.size) {
      this.disableUncheckedActivities();
    } else {
      this.enableAllActivities();
    }

    if (this.size !== this.selectedActivities) {
      this.submitTarget.disabled = true;
      return;
    }

    if (this.titleTarget.value.length === 0) {
      this.submitTarget.disabled = true;
      return;
    }

    this.submitTarget.disabled = false;
  }

  disableUncheckedActivities() {
    this.activityTargets.forEach((activity) => {
      if (!activity.checked) {
        activity.disabled = true;
      }
    });
  }

  enableAllActivities() {
    this.activityTargets.forEach((activity) => {
      activity.disabled = false;
    });
  }

  updateCount() {
    if (this.size > 0) {
      this.countTarget.innerText = `(${this.selectedActivities}/${this.size})`;
    }
  }
}
