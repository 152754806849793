import { Controller } from "@hotwired/stimulus";
import { Splide } from "@splidejs/splide";
import anime from 'animejs/lib/anime.es.js';

export default class CardDefinedFormController extends Controller {
  static targets = ["categoriesSlider", "categoryItem", "categoryPanelsWrapper", "categoryPanel", "size", "submit", "activity", "titleInput", "activityIdInput"];

  initialize() {
    this.size = null;
    this.activities = {};
    this.selectedActivities = [];
    this.selectedCategorySlug = null;
    this.initializeActivities();
    this.initializeSlider();

    if (isDevEnvironment()) {
      console.info(`[DEV] Card Defined Form Controller Initialized`);
    }
  }

  getSize() {
    const element = this.sizeTargets.find((size) => {
      return size.checked;
    });

    return parseInt(element.value);
  }

  initializeActivities() {
    this.showAllActivities();
    this.activities = {};

    this.categoryItemTargets.forEach((categoryItem) => {
      this.activities[categoryItem.dataset.slug] = [];
    });

    this.activityTargets.forEach((activity) => {
      this.activities[activity.dataset.categorySlug].push(activity.dataset.id);
    });
  }

  initializeSlider() {
    this.splide = new Splide(this.categoriesSliderTarget, {
      type: "slide",
      perPage: 4,
      perMove: 1,
      pagination: false,
      arrows: false,
      drag: false,
      gap: "1.5rem",
      breakpoints: {
        750: {
          perPage: 2,
          perMove: 2,
          drag: true,
          pagination: true,
        }
      }
    });

    this.splide.mount();
  }

  getCategoryItemBySlug(slug) {
    return this.categoryItemTargets.find((categoryItem) => {
      return categoryItem.dataset.slug == slug;
    });
  }

  getCategoryPanelBySlug(slug) {
    return this.categoryPanelTargets.find((categoryPanel) => {
      return categoryPanel.dataset.slug == slug;
    });
  }

  activateCategoryPanel(slug) {
    const categoryPanel = this.getCategoryPanelBySlug(slug);
    categoryPanel.classList.add("is-active");
    categoryPanel.ariaHidden = false;
    categoryPanel.focus();

    if (this.categoryPanelsWrapperTarget.classList.contains("is-active") == false) {
      this.categoryPanelsWrapperTarget.classList.add("is-active");
      this.categoryPanelsWrapperTarget.ariaHidden = false;
    }

    this.categoryPanelTargets.forEach((otherCategory) => {
      if (otherCategory.dataset.slug != categoryPanel.dataset.slug) {
        otherCategory.classList.remove("is-active");
        otherCategory.ariaHidden = true;
      }
    });
  }

  handleCategoryItemChange(event) {
    const categoryItem = this.getCategoryItemBySlug(event.target.dataset.slug);
    categoryItem.classList.add("is-active");
    this.selectedCategorySlug = categoryItem.dataset.slug;
    this.activateCategoryPanel(categoryItem.dataset.slug);
    this.resetSize();
    this.resetActivities();
    this.disableAllActivityInputs();
    this.validateForm();
    this.titleInputTarget.value = event.target.dataset.title;
    console.log(this.titleInputTarget.value); 

    this.categoryItemTargets.forEach((otherCategory) => {
      if (otherCategory.dataset.slug != categoryItem.dataset.slug) {
        otherCategory.classList.remove("is-active");
      }
    });
  }

  disableAllActivityInputs() {
    this.activityIdInputTargets.forEach((activityIdInput) => {
      activityIdInput.setAttribute("disabled", true);
    });
  }

  handleActivityInputsChange(selectedActivities) {
    this.activityIdInputTargets.forEach((activityIdInput) => {
      if (selectedActivities.includes(activityIdInput.dataset.activityId)) {
        activityIdInput.removeAttribute("disabled");
      } else {
        activityIdInput.setAttribute("disabled", true);
      }
    });
  }

  hideAllActivities() {
    this.activityTargets.forEach((activity) => {
      this.hideActivity(activity);
    });
  }

  hideActivity(activity) {
    anime({
      targets: activity.parentElement,
      opacity: [1, 0],
      scale: [1, 0],
      duration: 500,
      easing: "easeInOutQuad",
    }).finished.then(() => {
      activity.classList.add("is-hidden");
      activity.parentElement.classList.add("is-hidden");
      activity.ariaHidden = true;
    });
  }

  showAllActivities() {
    this.activityTargets.forEach((activity) => {
      this.showActivity(activity);
    });
  }

  showActivity(activity) {
    anime({
      targets: activity.parentElement,
      opacity: [0, 1],
      scale: [0, 1],
      duration: 500,
      easing: "easeInOutQuad",
      begin: () => {
        activity.classList.remove("is-hidden");
        activity.parentElement.classList.remove("is-hidden");
        activity.ariaHidden = false;
      }
    })
  }

  pickActivities() {
    // get activities from selected category
    this.selectedActivities = this.activities[this.selectedCategorySlug];

    // randomize activities
    this.selectedActivities = this.selectedActivities.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)

    // cut to right size
    this.selectedActivities = this.selectedActivities.slice(0, this.size);

    // handle activity inputs
    this.handleActivityInputsChange(this.selectedActivities);

    // show activities
    this.activityTargets.forEach((activity) => {
      if (this.selectedActivities.includes(activity.dataset.id)) {
        this.showActivity(activity);
      } else {
        this.hideActivity(activity);
      }
    });
  }

  resetSize() {
    this.sizeTargets.forEach((size) => {
      size.checked = false;
    });

    this.size = null;
  }

  resetActivities() {
    this.selectedActivities = [];
    this.showAllActivities();
  }

  handleSizeChange(event) {
    event.target.checked = true;
    this.size = this.getSize();
    this.pickActivities();
    this.validateForm();
  }

  validateForm() {
    if (this.size == null) {
      this.submitTarget.setAttribute("disabled", true);
      return;
    }

    this.submitTarget.removeAttribute("disabled");
  }

}
