import { Controller } from "@hotwired/stimulus";

import anime from "animejs/lib/anime.es.js";

export default class CardActivityItemController extends Controller {
  static values = {
    completed: Boolean,
    markAsCompletedText: String,
    markAsNotCompletedText: String,
    updateUrl: String
  };
  static targets = ["completeButtonLabel"];

  initialize() {
    this.element.addEventListener("activityUpdateCompleted", (event) => {
      this.updateCompleted(event.detail.completed, event.detail.content);
    });

    if (isDevEnvironment()) {
      console.info(`[DEV] Card Activity Item Controller Initialized`);
    }
  }

  completedValueChanged() {
    this.element.dataset.completed = this.completedValue;
  }

  updateActivity() {
    fetch(this.updateUrlValue, {
      method: "PATCH",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "text/html",
        "Accept": "text/html",
        "X-CSRF-Token": document.querySelector("meta[name='csrf-token']").content
      }
    }).then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error(`Response not ok: ${response.status}`);
      }
    }).then((data) => {
      if (isDevEnvironment()) {
        console.log(`[DEV] Successfully updated activity`);
      }

      const fragment = document.createRange().createContextualFragment(data);
      this.element.innerHTML = "";
      this.element.appendChild(fragment);

      anime({
        targets: this.element,
        rotateY: [90, 0],
        duration: 300,
        easing: "easeInOutQuad",
        begin: () => {
          if (this.completedValue) {
            this.element.classList.add("is-completed");
          } else {
            this.element.classList.remove("is-completed");
          }
        }
      });

      this.dispatch("activityUpdated", {
        detail: {
          index: parseInt(this.element.dataset.index),
          completed: this.completedValue
        }
      });
    });
  }

  updateActivityContent(content) {
    const fragment = document.createRange().createContextualFragment(content);
    this.element.innerHTML = "";
    this.element.appendChild(fragment);

    anime({
      targets: this.element,
      rotateY: [90, 0],
      duration: 300,
      easing: "easeInOutQuad",
      begin: () => {
        if (this.completedValue) {
          this.element.classList.add("is-completed");
        } else {
          this.element.classList.remove("is-completed");
        }
      }
    });
  }

  updateCompleted(isCompleted, content) {
    if (isCompleted) {
      this.markAsCompleted(content);
    } else {
      this.markAsNotCompleted(content);
    }
  }

  handleActivityButtonClick(event) {
    this.dispatch("activityButtonClicked", event);
  }

  markAsCompleted(content = null) {
    this.completedValue = true;
    this.completeButtonLabelTarget.textContent = this.markAsNotCompletedTextValue;
    anime({
      targets: this.element,
      rotateY: [0, 90],
      duration: 300,
      easing: "easeInOutQuad",
      complete: () => {
        if (!content) {
          this.updateActivity();
        } else {
          this.updateActivityContent(content);
        }
      }
    });
  }

  markAsNotCompleted(content = null) {
    this.completedValue = false;
    this.completeButtonLabelTarget.textContent = this.markAsCompletedTextValue;

    anime({
      targets: this.element,
      rotateY: [0, -90],
      duration: 300,
      easing: "easeInOutQuad",
      complete: () => {
        if (!content) {
          this.updateActivity();
        } else {
          this.updateActivityContent(content);
        }
      }
    });
  }

  handleCompleteButtonClick(event) {
    if (this.completedValue) {
      this.markAsNotCompleted();
    } else {
      this.markAsCompleted();
    }
  }
}
