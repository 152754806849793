import $ from 'jquery';

window.frontendValidation = function(action) {
  if (action=="defined") {
    var enableButton = checkCheckboxByName("card[size]") && checkCheckboxByName("card[category_id]");
  } else if (action=="custom") {
    var enableButton = checkCheckboxByName("card[size]") && checkCheckboxByNameAndSize("card[activity_ids][]") && cardName() ;
  } else {
    var enableButton = checkboxValidation() && inputFieldTextValidation() && inputFieldEmailValidation();
  }

  if (enableButton) {
    $(".js_submit_bttn").prop('disabled', false);
  } else {
    $(".js_submit_bttn").prop('disabled', true);
  }
};

function cardName() {
  var oneOrMoreAreEmpty = false;
  $("#card_title").each(function(){
    if( $(this).val().length == 0 ) { 
      oneOrMoreAreEmpty = true;
    }
  });
  // if one or more are empty return false
  return !oneOrMoreAreEmpty
};

function checkCheckboxByNameAndSize(name) {
  var cardSize = $(`input[name="card[size]"]:checked`).val();
  return $(`input[name="${name}"]:checked`).length == cardSize
};

function checkCheckboxByName(name) {
  return $(`input[name="${name}"]:checked`).length > 0
};

function checkboxValidation() {
  // all checkboxes must be true
  var allAreChecked = $("#user_accepts_gdpr").is(':checked');
  return allAreChecked;
};

function inputFieldTextValidation() {
  // check if text has length
  var oneOrMoreAreEmpty = false;
  $('input[type=text]').each(function(){
    if( $(this).val().length == 0 ) { 
      oneOrMoreAreEmpty = true;
    }
  });

  // if one or more are empty return false
  return !oneOrMoreAreEmpty
}

function inputFieldEmailValidation() {
  // check if email regex is ok
  var oneOrMoreAreInvalid = false;
  $('input[type=email]').each(function(){
    if( !isValidEmailAddress( $(this).val() ) ) { 
      oneOrMoreAreInvalid = true;
    };
  });
  // if one or more are invalid return false
  return !oneOrMoreAreInvalid
};

function isValidEmailAddress(emailAddress) {
  var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return pattern.test(emailAddress);
}
