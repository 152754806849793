window.handleDefinedActivities = function() {
  var size = $("[name='card[size]']:checked").val();
  var categoryId = $("[name='card[category_id]']:checked").val();
  var $hiddenFieldsContainer = $(".js-hidden_activity_ids");

  // get all activities
  var fullActivityIds = $(`.js-activities_in_${categoryId}`).map( (i, ele) => $(ele).data("activity_id") ).toArray();
  // make them random
  var randomizedActivityIds = fullActivityIds.map(value => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }) => value)
  // cut to right size
  var activityIds = randomizedActivityIds.slice(0, parseInt(size))

  // clear old hidden fields
  $hiddenFieldsContainer.empty();

  // hide and show activities and add them to hidden fields
  $(".js-single_activity").hide();
  for(var i=0; i < activityIds.length; i++) {
    // show activities
    $(`[data-activity_id="${activityIds[i]}"]`).show();

    // Template for making activity ids hidden fields
    let $hiddenFieldActivityIdClone = $(".js-activity_id_template").clone();

    // add hiden fields
    $hiddenFieldActivityIdClone.val(activityIds[i]);
    $hiddenFieldActivityIdClone.removeAttr("disabled");
    $hiddenFieldActivityIdClone.removeClass("js-activity_id_template"); // unless it will grow exponentialy
    $hiddenFieldsContainer.append($hiddenFieldActivityIdClone);
  }
};

window.handleDefinedSecondStep = function($that) {
  var slug = $that.data("slug");
  var title = $that.data("title");
  var categoryTitle = $that.data("categoryTitle");

  // hide and show title, size and activities
  $(".js-categories").hide();
  $(`.js-category_${slug}`).show();
  $(".js-activities").show();
  $(".js-subtitle").text(categoryTitle)
  $(".js-sizes").show();
  $(".js-activity_list").hide();
  $(`.js-activity_list_${slug}`).show();

  // fill data in hidden fields
  $(`.js-defiend_title`).val(title);
};
