window.disableCheckboxes = function() {
  // size is 16 of 9
  var size = parseInt( $("[name='card[size]']:checked").val() );
  // get num of all clicked checkboxes
  var numOfCheckedActivities = $(".js-checkbox:checked").length;
  if (numOfCheckedActivities < size) {
    // enable all
    $(".js-checkbox").removeAttr("disabled");
  } else {
    // disable all
    $(".js-checkbox").not(':checked').attr("disabled", true);
  }
};

