import { Controller } from "@hotwired/stimulus"
import { Splide } from "@splidejs/splide";

export default class CardActivitiesNavigationController extends Controller {
  static targets = ["navigation", "navigationItem", "cardsButton", "detailsButton", "detailsPanelWrapper", "detailsPanel", "cardsPanelWrapper", "cardsPanel"]

  initialize() {
    this.splide = null;
    this.splideFirstRun = true;
    this.hasDetailsPanelOpen = false;
    this.initializeSplide();

    if (isDevEnvironment()) {
      console.info(`[DEV] Card Activities Navigation Controller Initialized`);
    }
  }

  connect() {
    this.cardsButtonTargets[0].click();
  }

  initializeSplide() {
    const slides = this.navigationTarget.querySelectorAll(".splide__slide");
    // const startIndex = parseInt(slides.length / 2);

    this.splide = new Splide(this.navigationTarget, {
      type: "slide",
      perPage: 3,
      perMove: 3,
      pagination: false,
      arrows: false,
      // drag: false,
      // autoWidth: true,
      focus: "center",
      gap: "1rem",
      // start: startIndex,
      breakpoints: {
        668: {
          gap: 0,
          perPage: 1,
          perMove: 1,
          pagination: false,
        }
      }
    });

    this.splide.on("active", (slide) => {
      if (this.splideFirstRun) {
        this.splideFirstRun = false;
        return;
      }

      if (window.innerWidth <= 668) {
        const index = parseInt(slide.index);

        if (this.hasDetailsPanelOpen) {
          this.toggleDetailsPanel(index);
        }

        this.navigationItemTargets.forEach((navigationItem, i) => {
          if (i === index) {
            navigationItem.classList.add("is-active");
          } else {
            navigationItem.classList.remove("is-active");
          }

          navigationItem.classList.add('has-active-item');
        });

        this.openCardsPanel(index);
      }
    });

    this.splide.mount();
  }

  toggleDetailsPanel(index) {
    const detailsButton = this.detailsButtonTargets[index];
    let hasDetailsPanelOpen = false;
    this.detailsPanelTargets.forEach((detailsPanel, i) => {
      if (i === index) {
        detailsPanel.classList.toggle("is-open");

        const ariaHidden = detailsPanel.getAttribute("aria-hidden");
        ariaHidden === "true" ? detailsPanel.removeAttribute("aria-hidden") : detailsPanel.setAttribute("aria-hidden", "true");
        ariaHidden === "true" ? this.detailsPanelWrapperTarget.setAttribute("aria-hidden", "false") : this.detailsPanelWrapperTarget.setAttribute("aria-hidden", "true");

        detailsButton.classList.toggle("is-open");
        detailsButton.setAttribute("aria-expanded", detailsPanel.classList.contains("is-open"));

        if (detailsPanel.classList.contains("is-open")) {
          this.detailsPanelWrapperTarget.setAttribute("aria-hidden", "false");
          detailsPanel.focus();
          hasDetailsPanelOpen = true;
        }
      } else {
        detailsPanel.classList.remove("is-open");
        detailsPanel.setAttribute("aria-hidden", "true");

        this.detailsButtonTargets[i].classList.remove("is-open");
        this.detailsButtonTargets[i].setAttribute("aria-expanded", "false");
      }

      if (hasDetailsPanelOpen) {
        this.dispatch('closeDetailsPanel', { index: index })
      } else {
        this.dispatch('openDetailsPanel', { index: index })
      }

      this.hasDetailsPanelOpen = hasDetailsPanelOpen;
    });
  }

  closeDetailsPanel(index) {
    const detailsButton = this.detailsButtonTargets[index];
    const detailsPanel = this.detailsPanelTargets[index];

    detailsPanel.classList.remove("is-open");
    detailsPanel.setAttribute("aria-hidden", "true");

    detailsButton.classList.remove("is-open");
    detailsButton.setAttribute("aria-expanded", "false");

    this.detailsPanelWrapperTarget.removeAttribute("aria-hidden");

    this.navigationItemTargets.forEach((navigationItem, i) => {
      navigationItem.classList.remove("has-active-item");
    });

    this.dispatch('closeDetailsPanel', { index: index })
  }

  openCardsPanel(index) {
    const cardsButton = this.cardsButtonTargets[index];
    const cardsPanel = this.cardsPanelTargets[index];
    const navigationItem = this.navigationItemTargets[index];

    cardsPanel.classList.add("is-open");
    cardsPanel.setAttribute("aria-hidden", "false");

    cardsButton.classList.add("is-open");
    cardsButton.setAttribute("aria-expanded", "true");

    navigationItem.classList.add("has-active-item");
    navigationItem.classList.add("is-active");

    this.splide.go(index);


    this.cardsPanelWrapperTarget.setAttribute("aria-hidden", "false");

    this.cardsPanelTargets.forEach((cardsPanel, i) => {
      if (i !== index) {
        cardsPanel.classList.remove("is-open");
        cardsPanel.setAttribute("aria-hidden", "true");
      }
    });

    this.dispatch('openCardsPanel', { detail: { index: index } })
  }

  // Stimulus Actions

  handleNavigationItemClick(event) {
    event.stopPropagation();
    const index = parseInt(event.target.dataset.index);
    const cardsButton = this.cardsButtonTargets[index];

    cardsButton.click();
  }

  handleCardsButtonClick(event) {
    event.stopPropagation();
    const index = parseInt(event.target.dataset.index);

    if (this.hasDetailsPanelOpen) {
      this.toggleDetailsPanel(index);
    }

    this.navigationItemTargets.forEach((navigationItem, i) => {
      if (i === index) {
        navigationItem.classList.add("is-active");
      } else {
        navigationItem.classList.remove("is-active");
      }

      navigationItem.classList.add('has-active-item');
    });

    this.openCardsPanel(index);
  }

  handleDetailsButtonClick(event) {
    event.stopPropagation();
    const index = parseInt(event.target.dataset.index);
    const cardsButton = this.cardsButtonTargets[index];

    if (this.hasDetailsPanelOpen) {
      cardsButton.click();
    } else {
      this.toggleDetailsPanel(index);
    }
  }

  handleCloseDetailsPanelButtonClick(event) {
    event.stopPropagation();
    const index = parseInt(event.target.dataset.index);

    this.closeDetailsPanel(index);
  }
}
