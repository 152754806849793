window.loading = function(loading=true){
    if(loading) {
        $("body").addClass("is-loading");
    } else {
        $("body").removeClass("is-loading");
    }
    setTimeout(null, 0);
}

// Loading for ajax requests
$(document).on('turbolinks:load', function() {
    loading(false);
    var proxied = window.XMLHttpRequest.prototype.send;
    window.XMLHttpRequest.prototype.send = function() {
        loading();
        var pointer = this
        var intervalId = window.setInterval(function(){
                if(pointer.readyState != 4){
                    return;
                }
                else if(pointer.status==401){
                    dispatchAlertToast(I18n.t('error_message.unauthorized'));
                }
                else if(pointer.status==0){
                    dispatchAlertToast(I18n.t('error_message.no_connection'));
                }
                else if(pointer.status==500){
                    dispatchAlertToast(I18n.t('error_message.server_error'));
                }
                else if(pointer.status.toString()[0] != "2"){
                    dispatchAlertToast(I18n.t('error_message.generalized'));
                }

                loading(false);
          //initTippy();
                clearInterval(intervalId);
                
        }, 1);//I found a delay of 1 to be sufficient, modify it as you need.
        return proxied.apply(this, [].slice.call(arguments));
    };
});

$(document).on('turbolinks:click', function() {
  loading();
});

$(window).on('beforeunload', function(){
  loading();
});
