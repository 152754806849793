import { Controller } from "@hotwired/stimulus"
import { Splide } from "@splidejs/splide";

export default class ActivityShowController extends Controller {
  static targets = ["activity"];
  static values = {
    url: String
  }

  getActivity(id) {
    return this.activityTargets.find((activity) => {
      return activity.dataset.id == id;
    });
  }

  show(event) {
    var activityId = event.currentTarget.dataset.id;
    var activity = this.getActivity(activityId);
    const showActivitysUrl = event.currentTarget.dataset.showUrl;

    fetch(showActivitysUrl, {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "text/html",
        "Accept": "text/html",
      }
    }).then((response) => {
      if (response.ok) {
        return response.text();
      } else {
        throw new Error(`[DEV] Response not ok: ${response.status}`);
      }
    }).then((data) => {
      if (isDevEnvironment()) {
        console.log(`[DEV] Successfully fetched activity for ${activityId}`);
      }

      const fragment = document.createRange().createContextualFragment(data);
      openModal();
      const modalContent = document.querySelector(".js-modal-content");
      modalContent.innerHTML = "";
      modalContent.appendChild(fragment);

      //const modal = 
      //existingActivityParent.innerHTML = "";
      //existingActivityParent.appendChild(fragment);
    }).catch((error) => {
      console.error(`[DEV] Error: ${error}`);
    });

  }

  initialize() {
    
    if (isDevEnvironment()) {
      console.info(`[DEV] Activity Controller Initialized`);
    }
  }
};
