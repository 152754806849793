import { Controller } from "@hotwired/stimulus";
import Splide from "@splidejs/splide";

export default class CardActivitiesMainController extends Controller {
  static targets = ["activityItem", "mobileActivityItem", "mobileSlider"];
  
  initialize() {
    this.mobileSlider = null;
    
    if (isDevEnvironment()) {
      console.log("[DEV] Card Activities Main Controller Initialized");
    }

    this.initializeMobileSlider();
  }

  initializeMobileSlider() {
    this.mobileSlider = new Splide(this.mobileSliderTarget, {
      type: "slide",
      perPage: 1,
      perMove: 1,
      pagination: true,
      arrows: false,
      focus: "center",
      gap: "1rem",
    })

    this.mobileSlider.on( 'pagination:mounted', function ( data ) {
      data.list.classList.add( 'splide__pagination' );
    
      data.items.forEach( function ( item ) {
        item.button.textContent = String( item.page + 1 );
      } );
    } );

    this.mobileSlider.mount();
  }

  getNumberOfCompletedActivities() {
    return this.activityItemTargets.filter((activityItem) => {
      return activityItem.dataset.completed === "true";
    }).length;
  }

  async handleActivityItemUpdated(event) {
    const index = event.detail.index;
    const isCompleted = event.detail.completed;
    const activityItem = event.target;
    const isMobile = activityItem.classList.contains('is-mobile');

    if(isMobile) {
      await this.activityItemTargets[index].dispatchEvent(new CustomEvent("activityUpdateCompleted", {
        detail: {
          completed: isCompleted,
          content: this.mobileActivityItemTargets[index].innerHTML
        }
      }));
    } else {
      await this.mobileActivityItemTargets[index].dispatchEvent(new CustomEvent("activityUpdateCompleted", {
        detail: {
          completed: isCompleted,
          content: this.activityItemTargets[index].innerHTML
        }
      }));
    }

    const numberOfCompletedActivities = this.getNumberOfCompletedActivities();
    const numberOfActivities = this.activityItemTargets.length;

    if(numberOfCompletedActivities === numberOfActivities) {
      this.dispatch("allActivitiesCompleted");
    }
  }
}
