import { Controller } from "@hotwired/stimulus"
import anime from 'animejs/lib/anime.es.js';

export default class HeaderController extends Controller {
  static targets = ["description", "playButton", "wrapper", "logoContent"]

  connect() {
  }

  initialize() {
    this.animeOptions = {
      autoplay: false,
      easing: 'easeOutExpo',
      duration: 500
    }

    this.mainContent = document.querySelector('#MainContent');
    this.timeline = anime.timeline(this.animeOptions);
    this.setupTimeline();

    document.body.classList.add('header-is-overlay');

    this.playButtonTarget.addEventListener('click', () => {
      this.handlePlayButtonClick();
    });

    if (isDevEnvironment()) {
      console.info(`[DEV] Header Controller Initialized`);
    }
  }

  setupTimeline() {
    this.timeline.add({
      targets: this.descriptionTarget,
      opacity: [1, 0],
      height: 0,
      duration: 500,
      complete: () => {
        this.descriptionTarget.style.pointerEvents = 'none';
        this.descriptionTarget.style.visibility = 'hidden';
      },
    });

    this.timeline.add({
      targets: this.playButtonTarget,
      opacity: [1, 0],
      marginTop: 0,
      duration: 500,
      height: 0,
      fontSize: 0,
      padding: 0,
      complete: () => {
        this.playButtonTarget.style.pointerEvents = 'none';
        this.playButtonTarget.style.visibility = 'hidden';
        this.playButtonTarget.style.display = 'none';
      },
    }, '-=500');

    this.timeline.add({
      targets: this.element,
      height: ['100vh', '20.74vh'],
      duration: 1000,
    }, '-=500');

    this.timeline.add({
      targets: this.wrapperTarget,
      gap: ['6.25rem', '0.5rem'],
      maxHeight: 224,
      duration: 1000,
    }, '-=1000');

    this.timeline.add({
      targets: this.logoContentTarget,
      gap: ['1rem', '0rem'],
      duration: 1000,
    }, '-=1000');
  }

  handlePlayButtonClick() {
    if (this.element.classList.contains('is-shrinked')) {
      this.grow();
    } else {
      this.shrink();
    }
  }

  shrink() {
    document.body.classList.remove('header-is-overlay');
    this.element.classList.add('is-shrinked');
    this.timeline.play();
  }

  grow() {
    document.body.classList.add('header-is-overlay');
    this.element.classList.remove('is-shrinked');
    this.timeline.reverse();
  }
}
