import { Controller } from "@hotwired/stimulus";

export default class ActivityButtonController extends Controller {
  initialize() {
    if (isDevEnvironment()) {
      console.log("[DEV] Activity Button Controller Initialized")
    }
  }

  handleActivityButtonClick(event) {
    this.dispatch("activityButtonClicked", event);
  }
}
