import { Controller } from "@hotwired/stimulus";
import anime from 'animejs/lib/anime.es.js';
import Splide from "@splidejs/splide";

export default class ActivitiesDialogController extends Controller {
  static targets = ["container", "gallery"];

  initialize() {
    this.splide = null;

    if(isDevEnvironment()) {
      console.log("[DEV] Activities Dialog Controller Initialized")
    }
  }
  
  open() {
    anime({
      targets: this.element,
      opacity: [0, 1],
      duration: 300,
      easing: 'easeInOutQuad',
      begin: () => {
        this.element.showModal();
      }
    });
    document.documentElement.classList.add("has-activity-dialog-open");

    this.initializeSlider();
  }

  initializeSlider() {
    const slider = this.galleryTarget.querySelector(".js-slider");

    if (this.splide !== null) {
      this.destroySlider();
    }

    if (slider) {
      this.splide = new Splide(slider, {
        type: 'slide',
        perPage: 1,
        perView: 1,
        pagination: false,
        arrows: false,
      });
  
      this.splide.mount();
    }
  }

  destroySlider() {
    if (this.splide) {
      this.splide.destroy();
      this.splide = null;
    }
  }

  close(event) {
    event.preventDefault();
    event.stopPropagation();
    anime({
      targets: this.element,
      opacity: [1, 0],
      duration: 300,
      easing: 'easeInOutQuad',
      complete: () => {
        this.element.close();
      }
    });

    this.destroySlider();
    document.documentElement.classList.remove("has-activity-dialog-open");
  }

  async handleActivityButtonClicked(event) {
    event.preventDefault();
    event.stopPropagation();

    const activityButton = event.target;
    const showUrl = activityButton.dataset.showUrl;

    this.renderContent(showUrl).then(() => {
      this.open();
    })
  }

  async renderContent(showUrl) {
    const response = await fetch(showUrl, {
      method: "GET",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "text/html",
        "Accept": "text/html",
      }
    });
    const html = await response.text();
    const fragment = document.createRange().createContextualFragment(html);
    this.containerTarget.innerHTML = "";
    this.containerTarget.appendChild(fragment);
  }

  handleGalleryPrevButtonClick(event) {
    this.splide.go('<');
  }

  handleGalleryNextButtonClick(event) {
    this.splide.go('>');
  }
}
